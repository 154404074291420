import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Button, Image } from "react-bootstrap";


const Quiz = () => {
    const { id } = useParams();
    const [sorular, setSorular] = useState([]);
    const [aktifSoruIndex, setAktifSoruIndex] = useState(0);
    const [secilenCevapIndex, setSecilenCevapIndex] = useState(null);
    const [geriBildirim, setGeriBildirim] = useState(null); // Doğru veya yanlış geri bildirim durumu
    const [kullanıcıCevapları, setKullanıcıCevapları] = useState([]);
    const [sonucuGoster, setSonucuGoster] = useState(false);
    const [zamanlayıcı, setZamanlayıcı] = useState(30);

    useEffect(() => {
        const apiUrl = `https://panel.ehliyetyolda.com/api/data/webcikmissorular/${id}`;
        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                setSorular(data);
            })
            .catch(error => {
                console.error("API'den veri alınırken bir hata oluştu:", error);
            });
    }, [id]);

    useEffect(() => {
        if (zamanlayıcı > 0 && !sonucuGoster) {
            const interval = setInterval(() => {
                setZamanlayıcı(zamanlayıcı - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setSonucuGoster(true);
        }
    }, [zamanlayıcı, sonucuGoster]);

    const cevapSecildi = (index) => {
        setSecilenCevapIndex(index);
        const dogruCevap = sorular[aktifSoruIndex].correct_option;
        const dogruMu = index === dogruCevap.charCodeAt(0) - 65;
        setGeriBildirim(dogruMu ? "doğru" : "yanlış");
    };

    const sonrakineTıklandı = () => {
        if (secilenCevapIndex === null) return;
        const dogruCevap = sorular[aktifSoruIndex].correct_option;
        const secilenCevap = ['A', 'B', 'C', 'D'][secilenCevapIndex];

        const cevapKaydı = {
            soru_id: sorular[aktifSoruIndex].question_id,
            soru_metni: sorular[aktifSoruIndex].question_text,
            secilen_cevap: sorular[aktifSoruIndex][`option_${secilenCevap}`],
            dogru_cevap: sorular[aktifSoruIndex][`option_${dogruCevap}`],
            dogru_mu: secilenCevap === dogruCevap
        };
        setKullanıcıCevapları(prev => [...prev, cevapKaydı]);

        if (aktifSoruIndex < sorular.length - 1) {
            setAktifSoruIndex(prev => prev + 1);
            setSecilenCevapIndex(null);
            setZamanlayıcı(30); // Zamanlayıcıyı sıfırla
        } else {
            setSonucuGoster(true);
        }
    };

    if (!sorular.length) {
        return <div>Sorular yükleniyor...</div>;
    }

    return (
        <div>
            <Helmet>
                <title>Çıkmış Sorular </title>
            </Helmet>

            <Container>
                <Helmet>
                    <title>Çıkmış Sorular</title>
                </Helmet>

                <div className="quiz-container">
                    <div>
                        <div>İlerleme Çubuğu</div>
                        <div className="progressalani">
                            <div className="progress" style={{ width: `${(aktifSoruIndex + 1) / sorular.length * 100}%` }}></div>
                        </div>
                        <h4>{aktifSoruIndex + 1}/{sorular.length}</h4>
                        {sorular[aktifSoruIndex].option_A_resimyol && (
                            <div className="question-image">
                                <Image src={`https://panel.ehliyetyolda.com/${sorular[aktifSoruIndex].option_A_resimyol}`} fluid />
                            </div>
                        )}
                        <h5>{sorular[aktifSoruIndex].question_text}</h5>
                        <Row className="mt-5 mb-4">
                            {['A', 'B', 'C', 'D'].map((harf, index) => (
                                <Col key={index} md={12}>
                                    <Button className="cevapalani"
                                            variant={secilenCevapIndex === index ? (geriBildirim === "doğru" ? "success" : "danger") : "light"}
                                            onClick={() => cevapSecildi(index)}
                                            block>
                                        {sorular[aktifSoruIndex][`option_${harf}`]}
                                        {sorular[aktifSoruIndex][`option_${harf}_resimyol`] && (
                                            <div className="answer-image">
                                                <Image src={`https://panel.ehliyetyolda.com/${sorular[aktifSoruIndex][`option_${harf}_resimyol`]}`} fluid />

                                            </div>
                                        )}
                                    </Button>

                                </Col>
                            ))}
                        </Row>
                        <Button className="sonrakibutton" onClick={sonrakineTıklandı}>Sonraki</Button>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Quiz;
