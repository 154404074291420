import React from 'react';
import { Link } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { Container, Row, Col } from "react-bootstrap";


const kategoriler = [
    { id: 1, ad: 'Trafikle İlgili Tanımlar', path: '/trafik-tanimlar'},
    { id: 2, ad: 'Trafikle İlgili Kuruluşlar', path: '/trafik-kuruluslar'},
    { id: 3, ad: 'Hız Kuralları', path: '/hiz-kurallari'},
    { id: 4, ad: 'Trafik Psikolojisi', path: '/trafik-psikolojisi'},
    { id: 5, ad: 'Trafik İşaretleri', path: '/trafik-isaretleri'},
    { id: 6, ad: 'Ehliyet Sınıfları', path: '/ehliyet-siniflari'},
    { id: 7, ad: 'Poliş İşaretleri', path: '/polis-isaretleri'},

];


const Konular = () => {
    return (
        <div>

            <Container>
                <h2 className="mt-4 mb-2">Trafik Ve Çevre Bilgisi</h2>
                <Row>
                    <Col md={12}>
                        <ListGroup>
                            {kategoriler.map((kategori) => (
                                <ListGroup.Item action key={kategori.id} as="div">
                                    <Link to={kategori.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                                        {kategori.ad}
                                    </Link>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Konular;
