import React from 'react';
import Banner from "../../Component/Banner";
import { Helmet } from 'react-helmet';


const PolisIsaretleri = () => {
    return (
        <div>
            <Helmet>
                <title>Polis İşaretleri</title>
            </Helmet>
            <Banner title="Polis İşaretleri"/>
            <p>Polis İşaretleri</p>

        </div>
    );
};

export default PolisIsaretleri;
