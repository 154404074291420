import React from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'animate.css'; // animate.css kütüphanesini içe aktar

const kategoriler = [
    { id: 1, adi: 'Ders Notları', resimUrl: require('../assets/kategoriler/ders_notlari.webp'), path: '/ders-konulari' },
    { id: 2, adi: 'Çıkmış Sorular', resimUrl: require('../assets/kategoriler/cikmis_sorular.webp'), path: '/cikmis-sorular' },
    { id: 3, adi: 'Hız Limitleri', resimUrl: require('../assets/kategoriler/hiz_limitleri.webp'), path: '/hiz-kurallari' },
    { id: 4, adi: 'Ehliyet Sınıfları', resimUrl: require('../assets/kategoriler/ehliyet_siniflari.webp'), path: '/ehliyet-siniflari' },
    { id: 5, adi: 'Test Çöz', resimUrl: require('../assets/kategoriler/test_coz.webp'), path: '/soru-coz' },
    { id: 6, adi: 'Polis İşaretleri', resimUrl: require('../assets/kategoriler/polis_isaretleri.png'), path: '/polis-isaretleri' },
    { id: 7, adi: 'Trafik İşaretleri', resimUrl: require('../assets/kategoriler/trafik_isaretleri.webp'), path: '/trafik-isaretleri' }
];

const Kategoriler = () => {
    return (
        <Container style={{ marginTop: '20px' }}>
            <Row className="mb-5">
                <Col>
                    <h2>Kategoriler</h2>
                </Col>
            </Row>
            <Row>
                {kategoriler.map((kategori, index) => (
                    <Col key={kategori.id} xs={12} md={6} lg={3} className="mb-4">
                        <Card className="">
                            <Link to={kategori.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Card.Img variant="top" src={kategori.resimUrl} style={{ height: '200px', objectFit: 'cover' }} />
                            </Link>
                            <Card.Body>
                                <Link to={kategori.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Card.Title>{kategori.adi}</Card.Title>
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default Kategoriler;
