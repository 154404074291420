import React from 'react';
import Banner from "../../Component/Banner";
import {Container} from "react-bootstrap";
import {Helmet} from "react-helmet";

const styles = {
    section: {
        // Buraya stil özellikleri ekleyin
    },
    headerText: {
        marginBottom: 1,
        fontSize:'24px',

    },
    text: {
        // Buraya metin stil özellikleri ekleyin
        marginBottom:20,
    }
};
const TrafikKuruluslar = () => {
    return (
        <div>
            <Helmet>
                <title>Trafik Kuruluşlar</title>
            </Helmet>
            <Banner title="Trafik Tanımlar"/>
            <Container className="mt-5">

                <h2 style={styles.headerText}>Emniyet Genel Müdürlüğü</h2>
                <p style={styles.text}>
                    Türkiye'deki trafik düzenini sağlamak ve trafik güvenliğini artırmakla görevli devlet kuruluşudur. Emniyet Genel Müdürlüğü, trafik eğitimi, denetimi ve cezalandırma gibi alanlarda faaliyet gösterir.
                </p>


                <h2 style={styles.headerText}>Karayolları Genel Müdürlüğü</h2>
                <p style={styles.text}>
                    Karayollarının planlanması, yapımı, işletilmesi ve bakımından sorumlu olan kamu kuruluşudur. Karayolları Genel Müdürlüğü, yolların güvenliği ve düzeni için çalışmalar yürütür ve trafik işaretleri ile levhalarının düzenlenmesinden sorumludur.
                </p>

                <h2 style={styles.headerText}>Jandarma Genel Komutanlığı</h2>
                <p style={styles.text}>
                    Kırsal alanlarda ve belirli bölgelerde trafik güvenliğini sağlamakla görevli olan jandarma, trafik kurallarının uygulanmasını denetler ve trafik kazalarına müdahale eder. Jandarma ekipleri, trafik denetimleri ve yol kontrol noktaları kurarak trafik güvenliğini artırmaya çalışır.
                </p>

                <h2 style={styles.headerText}>İl Trafik Komisyonları</h2>
                <p style={styles.text}>
                    İl düzeyinde trafik düzenlemelerini ve trafikle ilgili kararları alan kuruluşlardır. İl Trafik Komisyonları, trafik sorunlarıyla ilgili çözüm önerileri geliştirir, trafik kazalarını analiz eder ve trafik güvenliği için tedbirler alır.
                </p>

                <h2 style={styles.headerText}>Belediyeler</h2>
                <p style={styles.text}>
                    Şehir içi trafiğin düzenlenmesi ve trafik güvenliğinin sağlanması amacıyla belediyeler önemli bir rol oynar. Belediyeler, trafik ışıkları, yaya geçitleri ve kavşak düzenlemeleri gibi trafik altyapı çalışmalarını yürütür ve trafik denetimlerini gerçekleştirir.
                </p>


            </Container>


        </div>
    );
};

export default TrafikKuruluslar;
