import React, { useEffect, useState } from 'react';
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import { Container, Row, Col, Button } from "react-bootstrap";


const Quiz = () => {
    const { id } = useParams();
    const [sorular, setSorular] = useState([]);
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
    const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
    const [feedback, setFeedback] = useState(null); // Doğru veya yanlış geri bildirim durumu
    const [userAnswers, setUserAnswers] = useState([]);
    const [showResult, setShowResult] = useState(false);
    const [timer, setTimer] = useState(30);

    useEffect(() => {
        const apiUrl = `https://panel.ehliyetyolda.com/api/data/randomquestion`;
        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                setSorular(data);
            })
            .catch(error => {
                console.error("API'den veri alınırken bir hata oluştu:", error);
            });
    }, [id]);

    useEffect(() => {
        if (timer > 0 && !showResult) {
            const interval = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setShowResult(true);
        }
    }, [timer, showResult]);

    const onAnswerSelected = (index) => {
        setSelectedAnswerIndex(index);
        const correctAnswer = sorular[activeQuestionIndex].correct_option;
        const isCorrect = index === correctAnswer.charCodeAt(0) - 65;
        setFeedback(isCorrect ? "correct" : "incorrect");
    };

    const onClickNext = () => {
        if (selectedAnswerIndex === null) return;
        const correctAnswer = sorular[activeQuestionIndex].correct_option;
        const selectedAnswer = ['A', 'B', 'C', 'D'][selectedAnswerIndex];

        const answerRecord = {
            question: sorular[activeQuestionIndex].question_text,
            selectedAnswer: sorular[activeQuestionIndex][`option_${selectedAnswer}`],
            correctAnswer: sorular[activeQuestionIndex][`option_${correctAnswer}`],
            isCorrect: selectedAnswer === correctAnswer
        };
        setUserAnswers(prev => [...prev, answerRecord]);

        if (activeQuestionIndex < sorular.length - 1) {
            setActiveQuestionIndex(prev => prev + 1);
            setSelectedAnswerIndex(null);
            setTimer(30); // Zamanlayıcıyı sıfırla
        } else {
            setShowResult(true);
        }
    };

    if (!sorular.length) {
        return <div>Loading questions...</div>;
    }
    const numericalProgress = `${activeQuestionIndex + 1}/${sorular.length}`;


    return (
        <div>
            <Helmet>
                <title>Çıkmış Sorular </title>
            </Helmet>

            <Container>


                <div className="quiz-container">
                    <div>
                        <div>İlerleme Çubuğu</div>
                        <div className="progressalani">


                            <div className="progress" style={{ width: `${(activeQuestionIndex + 1) / sorular.length * 100}%` }}>

                            </div>
                        </div>
                        <h4>{numericalProgress}
                        </h4>
                        <h5>{sorular[activeQuestionIndex].question_text}</h5>
                        <Row className="mt-5 mb-4">
                            {['A', 'B', 'C', 'D'].map((letter, index) => (
                                <Col key={index} md={12}>
                                    <Button className="cevapalani"
                                            variant={selectedAnswerIndex === index ? (feedback === "correct" ? "success" : "danger") : "light"}
                                            onClick={() => onAnswerSelected(index)}
                                            block>
                                        {sorular[activeQuestionIndex][`option_${letter}`]}
                                    </Button>
                                </Col>
                            ))}
                        </Row>
                        <Button className="sonrakibutton" onClick={onClickNext}>Sonraki</Button>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Quiz;