import React from 'react';
import Banner from "../../Component/Banner";
import {Helmet} from "react-helmet";
import Konular from "../../Component/Konular";




const DersKonulari = () => {
    return (
        <div>
            <Helmet>
                <title>Ders Konuları</title>
            </Helmet>
            <Banner title="Ders Konuları"/>

            <Konular/>
        </div>
    );
};

export default DersKonulari;
