import React from 'react';
import Banner from "../../Component/Banner";
import {Container} from "react-bootstrap";
import {Helmet} from "react-helmet";


const styles = {
    section: {
        // Buraya stil özellikleri ekleyin
    },
    headerText: {
        marginBottom: 1,
        fontSize:'24px',

    },
    text: {
        // Buraya metin stil özellikleri ekleyin
        marginBottom:20,
    }
};

const Trafikpsikolojisi = () => {
    return (
        <div>
            <Helmet>
                <title>Trafik Psikolojisi</title>
            </Helmet>
            <Banner title="Trafik Psikolojisi"/>
            <Container className="mt-5">

                <h2 style={styles.headerText}>Trafik Psikolojisi: Güvenli Sürüş ve Davranışları Anlamak</h2>
                <p style={styles.text}>
                    Trafik psikolojisi, trafik ortamında insan davranışlarını inceleyen ve trafik güvenliğini artırmayı amaçlayan bir disiplindir. Bu alanda, sürücülerin, yayaların ve diğer trafik katılımcılarının duygularını, düşüncelerini ve davranışlarını anlamak ve analiz etmek önemlidir.
                </p>


                <h2 style={styles.headerText}>Trafikteki Temel Unsurlar</h2>
                <p style={styles.text}>
                    Trafik, insanlar, araçlar ve çevre olmak üzere üç temel unsurdan oluşur. İnsanlar, sürücüler, yayalar ve yolcular olarak trafiğe katılırken, araçlar çeşitli motorlu ve motorsuz taşıtları temsil eder. Çevre ise trafik akışının gerçekleştiği yolları, kavşakları ve diğer altyapı unsurlarını içerir.
                </p>

                <h2 style={styles.headerText}>Güvenli Sürüş İçin Öneriler</h2>
                <p style={styles.text}>
                    Güvenli bir sürüş için sürücülerin dikkat etmeleri gereken bazı hususlar bulunmaktadır:

                    -Alkol veya uyuşturucu etkisi altında araç kullanmamak, çünkü bu durum sürücünün tepkilerini ve karar verme yeteneğini olumsuz etkiler.
                    -Araç teknik donanımının düzenli olarak kontrol edilmesi ve bakımının yapılması, böylece aracın güvenli bir şekilde kullanılmasını sağlar.
                    -Uykusuz veya dalgın halde araç sürmemek, çünkü bu durum sürücünün dikkatini dağıtabilir ve kazalara neden olabilir.
                    -Dikkatli ve sakin bir şekilde araç kullanmak, trafikteki diğer sürücülerle uyum içinde olmayı ve kazaları önlemeyi sağlar.
                </p>

                <h2 style={styles.headerText}>Trafik Psikolojisinin Temel Unsurları</h2>
                <p style={styles.text}>
                    Trafik psikolojisi, insan davranışlarını etkileyen çeşitli faktörleri inceleyerek trafik güvenliğini artırmayı amaçlar:


                    Sürücü Davranışları ve Karar Verme: Sürücülerin dikkat, algı ve karar verme süreçleri üzerinde durulur. Trafikte karşılaşılan durumları doğru bir şekilde değerlendirmek ve uygun tepkiler vermek önemlidir.
                    Trafik Stresi ve Yol Öfkesi: Trafik sıkışıklığı, zaman baskısı ve diğer sürücülerle etkileşimde yaşanan stresin etkileri incelenir. Bu durumlar sürücülerin dikkatini dağıtabilir ve kazalara neden olabilir.
                    Araç Kullanımı ve Teknoloji: Sürücü yardımcı sistemlerin ve otomatik sürüş teknolojilerinin psikolojik etkileri ele alınır. Bu teknolojilerin sürücülerin davranışları üzerindeki etkileri araştırılır ve trafik güvenliği açısından önemi değerlendirilir.
                </p>

                <h2 style={styles.headerText}>Trafik Psikolojisinin Önemi ve Uygulamaları</h2>
                <div style={styles.text}>
                    - Trafik psikolojisi, trafik güvenliği açısından önemli bir rol oynamaktadır. Bu alandaki çalışmalar şu şekillerde uygulanabilir:

                    <p style={styles.listItem}>Sürücü Eğitimi ve Davranışsal Değişim Programları: Sürücülerin trafik kurallarına uyumunu artırmak için eğitim programları düzenlenir ve davranışsal değişim teşvik edilir.</p>

                    <p style={styles.listItem}>Trafik Güvenliği Kampanyaları ve Bilinçlendirme Çalışmaları: Toplumda trafik güvenliği bilincini artırmak için çeşitli kampanyalar ve bilgilendirme çalışmaları yapılır.</p>

                    <p style={styles.listItem}>Trafik Altyapısı ve Yol Düzenlemeleri: Yolların güvenliğini artırmak için trafik psikolojisi prensiplerine dayalı olarak altyapı ve yol düzenlemeleri yapılır.</p>

                    <p style={styles.listItem}>Araç Teknolojisi ve Trafik Psikolojisi Araştırmaları: Araçlardaki teknolojik gelişmelerin sürücü davranışları üzerindeki etkilerini araştıran çalışmalar yapılır ve trafik güvenliğini artırmak için uygun teknolojiler geliştirilir.</p>

                    - Trafik psikolojisi, trafik ortamındaki davranışları daha iyi anlamak ve trafik güvenliğini artırmak için önemli bir araçtır. Bu disiplinin prensipleri ve uygulamaları, sürücülerin ve diğer trafik katılımcılarının daha güvenli ve bilinçli bir şekilde hareket etmelerine yardımcı olur.
                </div>


            </Container>


        </div>
    );
};

export default Trafikpsikolojisi;
