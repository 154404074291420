import React from 'react';
import { Container } from 'react-bootstrap';
import 'animate.css'; // animate.css kütüphanesini içe aktar

const Banner = ({ title }) => {
    return (
        <div className="banner-background">
            <div className="banner-dark-overlay">
                <Container className="banner-content text-center">
                    <h2 className="animate__animated animate__slideInLeft animate-left d-inline-block text-white ">Anasayfa / </h2>
                    <h2 style={{marginLeft:'10px'}} className="animate__animated animate__slideInRight animate-right d-inline-block text-white"> {title}</h2>
                </Container>
            </div>
        </div>
    );
};

export default Banner;
