import React, { useState } from 'react';
import Banner from "../../Component/Banner";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const Faq = () => {

    const faqs = [
        {
            question: 'Soru 1?',
            answer: 'Cevap 1.',
        },
        {
            question: 'Soru 2?',
            answer: 'Cevap 2.',
        },
        {
            question: 'Soru 3?',
            answer: 'Cevap 3.',
        },
    ];

    // SSS'lerin açık/kapalı durumunu saklayan state
    const [openIndex, setOpenIndex] = useState(null);

    // SSS'yi açma/kapama işlevi
    const toggleFaq = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    // Stil özellikleri
    const styles = {
        faqContainer: {
            marginTop: '50px',
        },
        faqHeader: {
            marginBottom: '30px',
        },
        faqQuestion: {
            cursor: 'pointer',
            marginBottom: '10px',
            fontSize: '20px',
        },
        faqQuestionHover: {
            color: '#007bff',
        },
        toggleIcon: {
            float: 'right',
            fontSize: '20px',
        },
        faqAnswer: {
            marginTop: '10px',
            paddingLeft: '20px',
        },
    };

    return (
        <div>
            <Helmet>
                <title>Sıkça Sorulanlar</title>
            </Helmet>
            <Banner title="Sıkça Sorulanlar"/>
            <Container style={styles.faqContainer}>
                <h2 style={styles.faqHeader}>Sıkça Sorulanlar</h2>

                <div className="faq-list">
                    {faqs.map((faq, index) => (
                        <div key={index} className="faq-item">
                            <div
                                style={{
                                    ...styles.faqQuestion,
                                    ...(openIndex === index && styles.faqQuestionHover),
                                }}
                                onClick={() => toggleFaq(index)}
                            >
                                {faq.question}
                                <span style={styles.toggleIcon}>{openIndex === index ? '-' : '+'}</span>
                            </div>
                            {openIndex === index && <div style={styles.faqAnswer}>{faq.answer}</div>}
                        </div>
                    ))}
                </div>
            </Container>
        </div>
    );
};

export default Faq;
