import React from 'react';
import {Container, Table} from "react-bootstrap";
import Banner from "../../Component/Banner";
import {Helmet} from "react-helmet";

const HizLimitleri = () => {
    const hizLimitleriData = [
        { tur: 'Otomobil', yerlesimIci: '50', ciftYonluKarayollari: '90', bolunmusYol: '110', otoyollar: '130-140' },
        { tur: 'Minibüs', yerlesimIci: '50', ciftYonluKarayollari: '80', bolunmusYol: '90', otoyollar: '100' },
        { tur: 'Otobüs', yerlesimIci: '50', ciftYonluKarayollari: '80', bolunmusYol: '90', otoyollar: '100' },
        { tur: 'Kamyonet', yerlesimIci: '50', ciftYonluKarayollari: '80', bolunmusYol: '85', otoyollar: '95' },
        { tur: 'Panelvan', yerlesimIci: '50', ciftYonluKarayollari: '85', bolunmusYol: '100', otoyollar: '110' },
        { tur: 'Kamyon', yerlesimIci: '50', ciftYonluKarayollari: '80', bolunmusYol: '85', otoyollar: '90' },
        { tur: 'Çekici', yerlesimIci: '50', ciftYonluKarayollari: '80', bolunmusYol: '85', otoyollar: '90' },
        { tur: 'Motosiklet', yerlesimIci: '30-50', ciftYonluKarayollari: '70-80', bolunmusYol: '80-90', otoyollar: '80-100' },
        { tur: 'Motorlu Bisiklet', yerlesimIci: '30', ciftYonluKarayollari: '45', bolunmusYol: '45', otoyollar: 'Giremez' },
        { tur: 'Motorsuz Bisiklet', yerlesimIci: '30', ciftYonluKarayollari: '45', bolunmusYol: '45', otoyollar: 'Giremez' },
        { tur: 'Tehlikeli madde', yerlesimIci: '30', ciftYonluKarayollari: '50', bolunmusYol: '50', otoyollar: '60' },
        { tur: 'Lastik tekerlekli traktörler', yerlesimIci: '20', ciftYonluKarayollari: '30', bolunmusYol: '40', otoyollar: 'Giremez' },
        { tur: 'Arızalı bir aracı çeken araçlar', yerlesimIci: '20', ciftYonluKarayollari: '20', bolunmusYol: '30', otoyollar: '40' },
        { tur: 'İş makineleri', yerlesimIci: '20', ciftYonluKarayollari: '20', bolunmusYol: '20', otoyollar: 'İzin Alınarak' },

    ];

    return (
        <div>
            <Helmet>
                <title>hız Limitleri</title>
            </Helmet>
            <Banner title="Hız limitleri"/>
            <Container className="mt-5">

                <h1>Hız Limitleri</h1>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Araç Türü</th>
                        <th>Yerleşim Yeri İçinde</th>
                        <th>Çift Yönlü Karayolları</th>
                        <th>Bölünmüş Yol</th>
                        <th>Otoyollar</th>
                    </tr>
                    </thead>
                    <tbody>
                    {hizLimitleriData.map((kayit, index) => (
                        <tr key={index}>
                            <td>{kayit.tur}</td>
                            <td>{kayit.yerlesimIci}</td>
                            <td>{kayit.ciftYonluKarayollari}</td>
                            <td>{kayit.bolunmusYol}</td>
                            <td>{kayit.otoyollar}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Container>
        </div>

    );
};

export default HizLimitleri;
