import React from 'react';
import {Container, Table} from 'react-bootstrap';
import Banner from "../../Component/Banner";
import {Helmet} from "react-helmet";
import Konular from "../../Component/Konular";


const EhliyetSiniflari = () => {
    const hizLimitleriData = [
        { sinifturu: 'M', araci: 'Motorlu Bisiklet', tecrube: '-', kapsam: '-', yas: '16',sure:'10 Yıl' },
        { sinifturu: 'A1', araci: '11 KW\'yı geçmeyen motosiklet', tecrube: '-', kapsam: '-', yas: '16',sure:'10 Yıl' },
        { sinifturu: 'A2', araci: '35 KW\'yı geçmeyen motosiklet', tecrube: '-', kapsam: 'M-A1', yas: '18',sure:'10 Yıl' },
        { sinifturu: 'A', araci: '35 KW\'yı geçen motosiklet', tecrube: 'En az 2 yıllık A2,24 yaş üzeri kişilerde tecrübe aranmaz', kapsam: 'M-A1-A2', yas: '20',sure:'10 Yıl' },
        { sinifturu: 'B1', araci: '4 tekerlekli motosiklet', tecrube: '-', kapsam: 'M', yas: '16',sure:'10 Yıl' },
        { sinifturu: 'B', araci: 'Otomobil - Kamyonet', tecrube: '-', kapsam: 'M-B1-F', yas: '18',sure:'10 Yıl' },
        { sinifturu: 'BE', araci: 'Römorklu Otomobil - Kamyonet', tecrube: 'B', kapsam: 'M-B1-B-F', yas: '18',sure:'10 Yıl' },
        { sinifturu: 'C1', araci: '7500 Kg\'a kadar kamyon ve çekici', tecrube: 'B', kapsam: 'M-B1-B-F', yas: '18',sure:'5 Yıl' },
        { sinifturu: 'C1E', araci: '12000 Kg\' kadar kamyon ve çekici', tecrube: 'C1', kapsam: 'M-B-BE-B1-C1-F', yas: '18',sure:'5 Yıl' },
        { sinifturu: 'C', araci: 'Kamyon ve Çekici', tecrube: 'B', kapsam: 'M-B-B1-C1-F', yas: '21',sure:'5 Yıl' },

        { sinifturu: 'CE', araci: 'Römorklu Kamyon ve Çekici', tecrube: 'C', kapsam: 'M-B-BE-B1-C-C1-C1E-F', yas: '21',sure:'5 Yıl' },
        { sinifturu: 'D1', araci: 'Minibüs', tecrube: 'B', kapsam: 'M-B-B1-F', yas: '21',sure:'5 Yıl' },
        { sinifturu: 'D1E', araci: 'Römorklu Minibüs', tecrube: 'D1', kapsam: 'M-B-BE-B1-D1-F', yas: '21',sure:'5 Yıl' },
        { sinifturu: 'D', araci: 'Minübüs ve Otobüs', tecrube: 'B', kapsam: 'M-B-BE-B1-D1-F', yas: '24',sure:'5 Yıl' },
        { sinifturu: 'DE', araci: 'Römorklu Minibüs ve Otobüs', tecrube: 'D', kapsam: 'M-B-BE-B1-D-D1-D1E-F', yas: '24',sure:'5 Yıl' },
        { sinifturu: 'F', araci: 'Traktör', tecrube: '-', kapsam: 'M', yas: '18',sure:'10 Yıl' },
        { sinifturu: 'G', araci: 'İş Makinası', tecrube: '-', kapsam: 'M', yas: '18',sure:'10 Yıl' },

    ];

    return (
        <div>
            <Helmet>
                <title>Ehliyet Sınıfları</title>
            </Helmet>
            <Banner title="Ehliyet Sınıfları"/>
            <Container className="mt-5">

                <h1>Ehliyet Sınıfları</h1>

                <div className="row">
                    <div className="col-md-12">
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>Ehliyet Sınıfı</th>
                                <th>Kullanacağı araç</th>
                                <th>Tecrübe</th>
                                <th>Kapsadığı Sınıflar</th>
                                <th>Yaş Şartı</th>
                                <th>Süre</th>

                            </tr>
                            </thead>
                            <tbody>
                            {hizLimitleriData.map((kayit, index) => (
                                <tr key={index}>
                                    <td>{kayit.sinifturu}</td>
                                    <td>{kayit.araci}</td>
                                    <td>{kayit.tecrube}</td>
                                    <td>{kayit.kapsam}</td>
                                    <td>{kayit.yas}</td>
                                    <td>{kayit.sure}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>

                    </div>
                    <div className="col-md-4">
                        <Konular/>
                    </div>
                </div>

            </Container>
        </div>
    );
};

export default EhliyetSiniflari;
