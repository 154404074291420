import React from 'react';
import {Helmet} from "react-helmet";

const Analizler = () => {
    return (
        <div>
            <Helmet>
                <title>Analizler</title>
            </Helmet>
            <p>Analizler</p>

        </div>
    );
};

export default Analizler;
