import React from 'react';
import {Container} from "react-bootstrap";
import Banner from "../../Component/Banner";
import {Helmet} from "react-helmet";

 const styles = {
     section: {
         // Buraya stil özellikleri ekleyin
     },
     headerText: {
        marginBottom: 1,
       fontSize:'24px',

     },
     text: {
         // Buraya metin stil özellikleri ekleyin
         marginBottom:20,
    }
};
const TrafikTanimlar = () => {
    return (
        <div>
            <Helmet>
                <title>Trafik Tanımlar</title>
            </Helmet>
            <Banner title="Trafik Tanımlar"/>
            <Container className="mt-5">

                <h2 style={styles.headerText}>Trafik</h2>
                <p style={styles.text}>
                    Trafik, kara yollarında araçların dolaşımıyla ilgili tüm faaliyetleri kapsar. Trafik, motorlu ve motorsuz araçlarla gerçekleştirilen hareketlilik olarak tanımlanır.
                </p>


                <h2 style={styles.headerText}>Taşıt</h2>
                <p style={styles.text}>
                    Karayollarında yolcu veya yük taşımak için kullanılan her türlü araca taşıt denir. Taşıtlar motorlu ve motorsuz olmak üzere ikiye ayrılır.
                </p>

                <h2 style={styles.headerText}>Sürücü</h2>
                <p style={styles.text}>
                    Herhangi bir taşıtı kullanma yetkisine sahip olan kişiye sürücü denir. Sürücüler, ehliyetleriyle belirlenir ve belirli trafik kurallarına uymakla yükümlüdürler.
                </p>

                <h2 style={styles.headerText}>Yaya</h2>
                <p style={styles.text}>
                    Karayolunda hareket eden, motorlu veya motorsuz taşıtlardan bağımsız olarak yürüyen kişilere yaya denir. Yaya geçitlerini kullanarak karşıdan karşıya geçmeleri önerilir.
                </p>

                <h2 style={styles.headerText}>Kavşak</h2>
                <p style={styles.text}>
                    İki veya daha fazla yolun birleştiği veya kesiştiği noktalara kavşak denir. Kavşaklarda dönüş ve geçiş kurallarına dikkat edilmelidir.
                </p>

                <h2 style={styles.headerText}>Şerit</h2>
                <p style={styles.text}>
                    Karayolunda araçların seyir halinde olduğu alanlara şerit denir. Şeritler, araçların yönlendirilmesi ve güvenli bir şekilde seyretmeleri için belirlenmiştir.
                </p>

                <h2 style={styles.headerText}>Hız Sınırı</h2>
                <p style={styles.text}>
                    Belirli bir alanda araçların maksimum hızlarını belirten kurallara hız sınırları denir. Hız sınırlarına uyulması, trafik güvenliği açısından büyük önem taşır.
                </p>

                <h2 style={styles.headerText}>Trafik İşareti</h2>
                <p style={styles.text}>
                    Karayollarında sürücülere yönlendirme, uyarı veya bilgilendirme amacıyla kullanılan işaretlere trafik işareti denir. Trafik işaretlerine dikkat ederek güvenli sürüş sağlanır.
                </p>

                <h2 style={styles.headerText}>Trafik Levhası</h2>
                <p style={styles.text}>
                    Karayollarında sürücülere bilgi vermek veya uyarıda bulunmak amacıyla kullanılan levhalara trafik levhası denir. Trafik levhaları, sürücülerin dikkatini çekmek ve trafik kurallarını hatırlatmak için önemlidir.
                </p>
            </Container>


        </div>
    );
};

export default TrafikTanimlar;
