import React from 'react';
import FeatureSection from "./Component/FeatureSection";
import Kategoriler from "./Component/Kategoriler";
import {Helmet} from "react-helmet";



const Home = () => {
    return (
        <div>
            <Helmet>
                <title>Ehliyet Pro</title>
            </Helmet>
            <div>

                <FeatureSection/>
                <Kategoriler/>
            </div>


        </div>
    );
};

export default Home;
