import React from 'react';
import Banner from "../../Component/Banner";
import {Container} from "react-bootstrap";
import {Helmet} from "react-helmet";


const styles = {
    section: {
        // Buraya stil özellikleri ekleyin
    },
    headerText: {
        marginBottom: 1,
        fontSize:'24px',

    },
    text: {
        // Buraya metin stil özellikleri ekleyin
        marginBottom:20,
    }
};

const GizlilikSartlari = () => {
    return (
        <div>
            <Helmet>
                <title>Gizlilik Şartları</title>
            </Helmet>
            <Banner title="Gizlilik Şartları"/>
            <Container className="mt-5">

                <h2 style={styles.headerText}>Gizlilik Şartlarımız</h2>
                <p style={styles.text}>
                    Biz, Ehliyet Pro Web Sitesi olarak, bireysel hakların ve özgürlüklerin korunması konusunda kararlıyız. Bu gizlilik bildirisi, sizin ve toplumun güvenliği için kişisel bilgilerin nasıl toplandığını, kullanıldığını ve korunduğunu açıklar.

                </p>


                <h2 style={styles.headerText}>Bilgi Toplama ve Kullanımı
                </h2>
                <p style={styles.text}>
                    Ehliyet Pro Websitesi, kullanıcıların kişisel bilgilerini, demokratik bir toplumun temelini oluşturan özgürlük ve gizlilik haklarına saygı göstererek toplar ve kullanır. Bu bilgiler, yalnızca hizmetlerimizi geliştirmek ve kullanıcı deneyimini iyileştirmek için kullanılır.
                </p>

                <h2 style={styles.headerText}>Üçüncü Taraf Hizmetleri ve Güvenlik

                </h2>
                <p style={styles.text}>
                    Üçüncü taraf hizmet sağlayıcıları, bilgi teknolojilerinin güvenliğini ve gizliliğini sağlamak için titizlikle seçilir ve denetlenir. Bilgilerinizin güvenliği, toplumun güvenliği için önceliklidir ve her türlü siber tehdide karşı aktif olarak korunur.
                </p>

                <h2 style={styles.headerText}>Bilgi Paylaşımı ve İstisnalar
                </h2>
                <p style={styles.text}>
                    Kullanıcı bilgileri, yasal gereklilikler veya kamu güvenliği gibi istisnai durumlar dışında, hiçbir şekilde üçüncü taraflarla paylaşılmaz veya ifşa edilmez. Bu bilgiler, yalnızca yasal gereksinimler doğrultusunda yetkili makamlarla paylaşılır.
                </p>

                <h2 style={styles.headerText}>Kullanıcı Hakları ve İletişim
                </h2>
                <p style={styles.text}>
                    Kullanıcılarımızın kişisel bilgileri üzerinde tam kontrol sahibi olmalarını sağlamak için gerekli olan tüm haklar tanınır. Bilgiye erişme, düzeltme ve silme gibi haklarınızı kullanmak için [iletişim bilgileri] üzerinden bizimle iletişime geçebilirsiniz.
                </p>


            </Container>


        </div>
    );
};

export default GizlilikSartlari;
