import 'bootstrap/dist/css/bootstrap.min.css';
import AppNavbar from "./Component/Navbar";
import Topbar from "./Component/Topbar";
import './style.css';
import Footer from "./Component/Footer";
import CikmisSorular from "./Pages/CikmisSorular/CikmisSorular";
import React from "react"; // Oluşturduğunuz style.css dosyasını import edin
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';
import Home from "./Home";
import PolisIsaretleri from "./Pages/Konular/PolisIsaretleri";
import EhliyetSiniflari from "./Pages/Konular/EhliyetSiniflari";
import TrafikIsaretleri from "./Pages/Konular/TrafikIsaretleri";
import HizLimitleri from "./Pages/Konular/HizLimitleri";
import Analizler from "./Pages/Konular/Analizler";
import TrafikTanimlar from "./Pages/Konular/TrafikTanimlar";
import TrafikKuruluslar from "./Pages/Konular/TrafikKuruluslar";
import TrafikPsikolojisi from "./Pages/Konular/TrafikPsikolojisi";
import Quiz from "./Pages/CikmisSorular/Quiz";
import ScrollToTopOnMount from "./Component/ScrollToTopOnMount";
import DersKonulari from "./Pages/Konular/DersKonulari";
import Gizlilik_sartlari from "./Pages/Sayfalar/gizlilik_sartlari";
import KullanimKosullari from "./Pages/Sayfalar/kullanim_kosullari";
import CerezPolitikasi from "./Pages/Sayfalar/cerez_politikasi";
import Faq from "./Pages/Sayfalar/faq";
import TestCoz from "./Pages/Testcoz/TestCoz";


function App() {
  return (
      <Router>
          <ScrollToTopOnMount />

          <div>
              <Topbar/>
              <AppNavbar/>
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/cikmis-sorular" element={<CikmisSorular />} />
                  <Route path="/ders-konulari" element={<DersKonulari />} />
                  <Route path="/ehliyet-siniflari" element={<EhliyetSiniflari />} />
                  <Route path="/trafik-isaretleri" element={<TrafikIsaretleri />} />
                  <Route path="/hiz-kurallari" element={<HizLimitleri />} />
                  <Route path="/polis-isaretleri" element={<PolisIsaretleri />} />
                  <Route path="/analizler" element={<Analizler />} />
                  <Route path="/trafik-tanimlar" element={<TrafikTanimlar />} />
                  <Route path="/trafik-kuruluslar" element={<TrafikKuruluslar />} />
                  <Route path="/trafik-psikolojisi" element={<TrafikPsikolojisi />} />
                  <Route path="/gizlilik-politikasi" element={<Gizlilik_sartlari />} />
                  <Route path="/kullanim-kosullari" element={<KullanimKosullari />} />
                  <Route path="/cerez-politikasi" element={<CerezPolitikasi />} />
                  <Route path="/sss" element={<Faq />} />
                  <Route path="/test-coz/:id" element={<Quiz />} />
                  <Route path="/soru-coz" element={<TestCoz />} />
              </Routes>
              <Footer/>


          </div>
      </Router>

  );
}

export default App;
