import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaAndroid, FaApple } from 'react-icons/fa'; // İkonları import edin
import 'animate.css'; // animate.css kütüphanesini içe aktar


const FeatureSection = () => {
    return (
        <Container fluid style={{ background: "#b9c8ee" }}>
            <Row className="align-items-center">
                <Col md={5} className="text-center" style={{ paddingTop: '20px' }}>
                    <Container className="animate__animated animate__slideInLeft animate-left">
                        <h1>Ehliyet Çıkmış Sorular</h1>
                        <p style={{ fontSize: '22px', margin: '20px 10px' }}>
                            Ehliyet Çıkmış Sorular uygulaması ile sınav öncesi hazırlığınızı en üst seviyeye taşıyın.
                            Binlerce çıkmış soru ve detaylı açıklamaları ile sınavda başarınızı garantileyin.
                        </p>
                        <div className="text-center d-none d-md-block">
                            <Button style={{ padding: '12px 15px', fontSize: '23px', borderRadius: '14px', marginRight: '10px' }} className="button-custom button-android">
                                <FaAndroid /> Android İndir
                            </Button>
                            <Button style={{ padding: '12px 15px', fontSize: '23px', borderRadius: '14px' }} className="button-custom button-ios">
                                <FaApple /> iOS İndir
                            </Button>
                        </div>
                    </Container>
                </Col>
                <Col md={7} className="text-center">
                    <img src={require("../assets/ehliyet_yolda.png")} alt="Slider Resmi" className="feature-image animate__animated animate__slideInRight animate-right" style={{ maxWidth: '100%', height: 'auto', maxHeight: '520px' }} />
                </Col>
                <Col md={12} className="text-center d-block d-md-none">
                    <div style={{marginBottom:'10px'}} className="text-center">
                        <Button style={{ padding: '12px 17px', fontSize: '19px', borderRadius: '14px',marginTop:'10px' }} className="button-custom button-android">
                            <FaAndroid /> Android İndir
                        </Button>
                        <Button style={{ padding: '12px 17px', fontSize: '19px', marginLeft: '15px', borderRadius: '14px',marginTop:'10px' }} className="button-custom button-ios">
                            <FaApple /> iOS İndir
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default FeatureSection;
