import React from 'react';
import Banner from "../../Component/Banner";
import {Container} from "react-bootstrap";
import {Helmet} from "react-helmet";


const styles = {
    section: {
        // Buraya stil özellikleri ekleyin
    },
    headerText: {
        marginBottom: 1,
        fontSize:'24px',

    },
    text: {
        // Buraya metin stil özellikleri ekleyin
        marginBottom:20,
    }
};

const CerezPolitikasi = () => {
    return (
        <div>
            <Helmet>
                <title>Çerez Politikası</title>
            </Helmet>
            <Banner title="Çerez Politikası"/>
            <Container className="mt-5">

                <h2 style={styles.headerText}>Çerez Politikası</h2>
                <p style={styles.text}>
                    Ehliyet Pro web sitesi, kullanıcı deneyimini iyileştirmek ve siteyi daha verimli hale getirmek için çerezler kullanır. Bu çerez politikası, kullanıcıların çerezlerle ilgili haklarını ve çerezlerin nasıl kullanıldığını açıklar.
                </p>


                <h2 style={styles.headerText}>Çerezlerin Kullanımı</h2>
                <p style={styles.text}>
                    Ehliyet Pro web sitesi, kullanıcıların siteyi nasıl kullandığını analiz etmek, siteyi kişiselleştirmek ve reklamları göstermek için çerezler kullanır. Bu çerezler, kullanıcı deneyimini iyileştirmek ve siteyi daha kullanıcı dostu hale getirmek amacıyla kullanılır.
                </p>

                <h2 style={styles.headerText}>Üçüncü Taraf Çerezleri</h2>
                <p style={styles.text}>
                    Ehliyet Pro web sitesi, üçüncü taraf hizmet sağlayıcıları tarafından sağlanan çerezleri de kullanabilir. Bu çerezler, reklamları kişiselleştirmek ve hedeflemek, site trafiğini analiz etmek ve site performansını ölçmek için kullanılabilir.
                </p>

                <h2 style={styles.headerText}>Çerezleri Kontrol Etme</h2>
                <p style={styles.text}>
                    Kullanıcılar, tarayıcı ayarları aracılığıyla çerezleri kabul etmeyi veya reddetmeyi seçebilirler. Ancak, çerezleri reddetmek, sitenin bazı özelliklerinin çalışmasını engelleyebilir veya kullanıcı deneyimini olumsuz etkileyebilir.
                </p>

                <h2 style={styles.headerText}>Çerez Politikası Değişiklikleri</h2>
                <p style={styles.text}>
                    Bu çerez politikası, zaman zaman güncellenebilir veya değiştirilebilir. Değişiklikler web sitesinde yayınlanacak ve değişiklikler yürürlüğe girdikten sonra geçerli olacaktır. Kullanıcılar, çerez politikasında yapılan değişiklikleri düzenli olarak kontrol etmelidirler.
                </p>



            </Container>


        </div>
    );
};

export default CerezPolitikasi;
