
import React from 'react';
import {Card, Row, Col, Container} from 'react-bootstrap';
import Banner from "../../Component/Banner";
import {Helmet} from "react-helmet";

// Trafik işaretleri veri seti örneği
const trafikIsaretleri = [
    { id: 1,title:'Sağa tehlikeli viraj (T-1a)', resimUrl: require('../../assets/isaretler/saga_tehlikeli_viraj.png')},

    { id: 2,title:'Sola tehlikeli viraj (T-1b)', resimUrl: require('../../assets/isaretler/sola_tehlikeli_viraj.png')},

    { id: 3,title:'Sağa tehlikeli devamlı virajlar (T-2a)', resimUrl: require('../../assets/isaretler/saga_tehlikeli_devamli_viraj.png')},


    { id: 4,title:'Sola tehlikeli devamlı virajlar (T-2b)',resimUrl: require('../../assets/isaretler/sola_tehlikeli_viraj_devamli.png')},

    { id: 5,title:'Tehlikeli eğim (iniş) (T-3a)', resimUrl: require('../../assets/isaretler/5_tehlikeli_egim.png')},

    { id: 6,title:'Tehlikeli eğim (çıkış) (T-3b)',resimUrl: require('../../assets/isaretler/6_tehlikeli_egim_cikis.png')},

    { id: 7,title:'Her iki taraftan daralan kaplama (T-4a)',resimUrl: require('../../assets/isaretler/7_iki_taraftan_daralan_kaplama.png')},

    { id: 8,title:'Sağdan daralan kaplama (T-4b)',resimUrl: require('../../assets/isaretler/8_sagdan_daralan_kaplama.png')},
    { id: 9,title:'Soldan daralan kaplama (T-4c)',resimUrl: require('../../assets/isaretler/9_soldan_daralan_kaplama.png')},
    { id: 10,title:'Açılan köprü (T-5)',resimUrl: require('../../assets/isaretler/10_acilan_kopru.png')},
    { id: 11,title:'Deniz veya nehir kıyısında biten yol (T-6)',resimUrl: require('../../assets/isaretler/11_deniz_veya_kiyida_biten_yol.png')},
    { id: 12,title:'Kasisli yol (T-7)',resimUrl: require('../../assets/isaretler/12_kasisli_yol.png')},
    { id: 13,title:'Kaygan yol (T-8)',resimUrl: require('../../assets/isaretler/13_kaygan_yol.png')},
    { id: 14,title:'Gevşek malzemeli zemin (T-9)',resimUrl: require('../../assets/isaretler/14_gevsek_malzemeli_zemin.png')},
    { id: 15,title:'Gevşek şev (T-10)',resimUrl: require('../../assets/isaretler/15_gevsek_sev.png')},
    { id: 16,title:'Yaya geçidi (T-11)',resimUrl: require('../../assets/isaretler/16_yay_gecidi.png')},
    { id: 17,title:'Okul geçidi (T-12)',resimUrl: require('../../assets/isaretler/17_okul_gecidi.png')},
    { id: 18,title:'Bisiklet geçebilir (T-13)',resimUrl: require('../../assets/isaretler/18_bisiklet_gecebilir.png')},
    { id: 19,title:'Ehli hayvanlar geçebilir (T-14a)',resimUrl: require('../../assets/isaretler/19_ehli_hayvanlar_gecebilir.png')},
    { id: 20,title:'Vahşi hayvanlar geçebilir (T-14b)',resimUrl: require('../../assets/isaretler/20_vahsi_hayvanlar_gecebilir.png')},
    { id: 21,title:'Işıklı işaret cihazı (T-16)',resimUrl: require('../../assets/isaretler/21_isikli_isaret_cihazi.png')},
    { id: 22,title:'Havalimanı (alçak uçuş) (T-17)',resimUrl: require('../../assets/isaretler/22_havalimani_alcak_ucus.png')},
    { id: 23,title:'Yandan rüzgar (T-18)',resimUrl: require('../../assets/isaretler/23_yandan_ruzgar.png')},






];

const TrafikIsaretleri = () => {
    return (
        <div>
            <Helmet>
                <title>Trafik İşaretleri</title>
            </Helmet>
            <Banner title="Trafik İşaretleri"/>
            <Container className="mt-5">
                <Row xs={1} md={2} lg={4} className="g-0">
                    {trafikIsaretleri.map((isaret) => (
                        <Col key={isaret.id} className="">
                            <Card className="custom-card">
                                <div className="d-flex justify-content-center mt-3">
                                    <Card.Img style={{ width: '50%' }} variant="top" src={isaret.resimUrl} className="mx-auto d-block"/>
                                </div>
                                <Card.Body className="custom-card-body">
                                    <Card.Title className="text-center" style={{fontSize: '17px', fontWeight: 'bold', marginBottom: '5px',height:50}}>{isaret.title}</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>

        </div>
    );
};

export default TrafikIsaretleri;
